body {
    --bs-btn-disabled-border-color: transparent;
}

.max-height-250 {
    max-height: 250px;
    overflow: auto;
}

.table-wrapper {
    max-height: 650px;
    overflow: auto;

    table {
        background: #fff;
        margin-bottom: 0;
    }
}

.table-fixed-header {
    thead th {
        position: sticky;
        top: 0;
        z-index: 1;
    }

    tbody {
        border-top: 0 !important;
    }
}

.thead-white {
    tr {
        th {
            color: #000;
            background-color: #fff;
            border-color: #eee;
        }
    }
}

.accordion-button {
    &:not(.collapsed) {
        background-color: unset !important;
    }

    &:focus {
        border-color: unset !important;
        box-shadow: unset !important;
    }
}

.accordion-right-extra {
    float: right;
    margin-right: 10px;
    flex: 1 0;
    text-align: right;
}

.action-button {
    padding: 0 10px !important;
    width: 36px;
}

@mixin btn-custom($color) {
    background-color: $color !important;

    &:hover {
        background-color: darken($color, 10%) !important;
    }

    &:active {
        background-color: lighten($color, 10%) !important;
    }

    &:focus {
        box-shadow: none !important;
    }

    &.action-button {
        background-color: unset !important;
        color: $color !important;

        &:hover {
            background-color: $color !important;
            color: #fff !important;
        }

        &:active {
            background-color: lighten($color, 10%) !important;
        }
    }

    &.action-button-outlined {
        background-color: $color !important;
        color: #fff !important;

        &:hover {
            background-color: darken($color, 10%) !important;
        }

        &:disabled {
            background-color: lighten($color, 20%) !important;
        }
    }
}

@mixin text-custom($color) {
    color: $color;
}

@mixin bg-custom($color) {
    background: $color;
}

@mixin color-custom($color, $name) {
    .btn-#{$name} {
        @include btn-custom($color);
    }

    .bg-#{$name},
    .table-#{$name} {
        @include bg-custom($color);
    }

    .text-#{$name} {
        @include text-custom($color);
    }
}

@include color-custom($color: $grey, $name: 'grey');
@include color-custom($color: $red, $name: 'red');
@include color-custom($color: $green, $name: 'green');
@include color-custom($color: $blue, $name: 'blue');
@include color-custom($color: $orange, $name: 'orange');
@include color-custom($color: $purple, $name: 'purple');
@include color-custom($color: $dark-blue, $name: 'dark-blue');
@include color-custom($color: $dark-grey, $name: 'dark-grey');
@include color-custom($color: $grey-blue, $name: 'grey-blue');
@include color-custom($color: $green-success, $name: 'green-success');
@include color-custom($color: $yellow-waning, $name: 'yellow-waning');
@include color-custom($color: $red-error, $name: 'red-error');

@mixin single-line-ellipsis-trim($width) {
    white-space: nowrap;
    width: $width;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-log-user {
    @include single-line-ellipsis-trim($width: 150px);
}

.event-log-summary {
    @include single-line-ellipsis-trim($width: 400px);
}

.agent-installed-location {
    @include single-line-ellipsis-trim($width: 400px);
}

.environment-comparison {
    @include single-line-ellipsis-trim($width: 250px);
}

.shell-command {
    margin: 0 3px;
    padding: 0 3px;
    height: 20px;
    display: inline-block;
    margin-bottom: 18px;
}

.command-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    font-family: Consolas;
    max-width: 429px;
    vertical-align: bottom !important;
    height: 20px;
    line-height: 1px !important;
}

div.droppable-hover {
    position: relative;

    &::before {
        content: '+';
        position: absolute;
        font-size: 60px;
        font-weight: 500;
        background: rgba($color: $success, $alpha: 0.6);
        opacity: 0.5;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.react-tooltip {
    z-index: 1 !important;
}

.nav-link > .row-count {
    @extend .bg-primary;
}

.nav-link.active > .row-count {
    @extend .bg-light;
    @extend .text-dark;
}

.container {
    max-width: 100%;
}

.link {
    cursor: pointer;
    color: var(--bs-link-color);

    &:hover {
        color: var(--bs-link-hover-color);
    }
}
