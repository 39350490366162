h1,
h2,
h3,
h4,
h5,
h6 {
    &.accordion-header {
        margin-top: 0 !important;
    }
}

.nav-link {
    &.active {
        color: #fff !important;
        background-color: #263f6a !important;
    }
    color: #263f6a !important;
}

tr.disabled {
    pointer-events: none;
}

div.validation-error,
input.validation-error,
textarea.validation-error,
select.validation-error {
    border: 1px solid #ed5565 !important;
}

.details-link {
    pointer-events: all;
}
